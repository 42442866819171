@import '../../styles';

.page--ubi-sim {
  width: 100vw;
  height: 100vh;
  background-color: $color__gray--dark;

  .page--ubi-sim__container {
    @include container;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .page--ubi-sim__intensity-indicator {
      padding: 12px;
      border: 1px solid $color__gray--light;
      width: 160px;

      .page--ubi-sim__label {
        margin-bottom: 12px;
      }

      .page--ubi-sim__intensity-indicator__dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $color__white;
      }
    }
  }
}