@import './variables.scss';

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin: 0;
  white-space: pre-wrap;

  &.inverse {
    color: $color__white;
  }

  &.all-caps {
    text-transform: uppercase;
  }
}

h1 {
  color: $color__gray--dark;
  font-size: 64px;
  line-height: 80px;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }
}

h2 {
  color: $color__gray--dark;
  font-size: 44px;
  line-height: 58px;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }
}

h3 {
  color: $color__gray--dark;
  font-size: 36px;
  line-height: 44px;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }
}

h4 {
  color: $color__gray--dark;
  font-size: 28px;
  line-height: 36px;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }
}

h5 {
  color: $color__gray--dark;
  font-size: 22px;
  line-height: 28px;
  font-family: $font-name--sans;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }
}

h6 {
  color: $color__gray--dark;
  font-size: 18px;
  line-height: 24px;
  font-family: $font-name--sans;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }
}

p {
  color: $color__gray--medium;
  font-size: 14px;
  line-height: 22px;
  font-family: $font-name--sans;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }

  &.small {
    font-size: 12px;
  }
}

a {
  text-decoration: none;
  // color: $color__action;
  font-weight: bold;
  font-size: 14px;
  font-family: $font-name--sans;
  font-weight: 500;

  &.inverse {
    color: $color__white;
  }

  &.external {
    text-decoration: underline;
  }
}

br {
  line-height: 150%;
}

.bold {
  font-weight: bold;
}

span {
  &.italic {
    font-style: italic;
  }
}

@media only screen and (max-width: $viewport-small) {
  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
}