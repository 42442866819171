@import '../../../../styles/';

.node--finger {
  padding: 10px;
  border-radius: 3px;
  background-color: $color__white;
  border-width: 1px;
  border-style: solid;
  border-color: $color__gray--dark;
}
