@import '../../../../styles/';
@import '../nodes';

.node--timeline {
  width: 500px;

  .node--timeline__edit-row {
    display: flex;
    justify-content: space-between;

    .node--timeline__edit__actions {
      margin-bottom: 10px;

      .node--timeline__edit__actions__wrapper {
        display: flex;

        .node--timeline__edit__action__button {
          cursor: pointer;
          width: 28px;
          height: 28px;
          background-color: $color__gray--dark;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2px;
          padding: 4px;

          &.active {
            background-color: $color__green;
          }
        }
        
        .node--timeline__edit__actions__remove {
          &.active {
            background-color: $color__red;
          }
        }

        .divider {
          width: 1px;
          height: 24px;
          background-color: $color__gray--light;
          margin: 0 5px;
        }
      }
    }

    .node--timeline__bpm-fields {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .node--timeline__bpm-fields__label {
        margin-right: 5px;
        font-size: 12px;
      }

      .node--timeline__bpm-fields__input {
        width: 50px;
        height: 20px;
        padding: 0 5px;
        border: 1px solid $color__gray--light;
        border-radius: 3px;
        text-align: center;
      }
    }
  }

  .node--timeline__container {
    overflow-x: scroll;
    cursor: pointer;

    .node--timeline__graph {
      width: 105%;
      height: 200px;
      // height: 2000%;
      position: relative;
  
      .node--timeline__graph__cells {
        position: absolute;
        top: 20px;
        left: 0;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        // padding: 20px;
        // background-color: $color__gray--light;
        opacity: 0.2;
        display: grid;
        // grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(10, 10%);

        .node--timeline__graph__cell {
          width: 100%;
          height: 100%;
          border-left: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;

          &.major {
            border-left-width: 2px;
          }

          .node--timeline__graph__cell__label {
            position: relative;
            top: -20px;
            left: 0px;
            font-size: 12px;
            padding: 2px;
          }
        }
      }
      
      .node--timeline__graph__notes {
        width: 100%;
        height: 100%;
        
        .node--timeline__graph__note {
          position: absolute;
          bottom: 20%;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $color__white;
          opacity: 0.2;

          &:hover {
            opacity: 0.5;
            cursor: ns-resize;
          }
        }
      }
    }
  }
}