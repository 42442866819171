@import './typography.scss';
@import './grid.scss';
@import './variables.scss';

input[type="text"],
input[type="number"] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $color__gray--light;
  color: $color__white;
  outline: none;
  text-overflow: ellipsis;
  padding: 0.5rem 0;
}

button {
  background-color: $color__gray--dark;
  border: none;
  color: $color__white;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;

  &.small {
    padding: 0.25rem;
  }
}