@import '../../../styles/';

.node--button,
.node--finger,
.node--timeline {
  color: $color__white;
  padding: 10px;
  border-radius: 3px;
  background-color: rgba($color__white, 0.25);
  border-width: 1px;
  border-style: solid;
  border-color: rgba($color__white, 0.75);
}