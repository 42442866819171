@import '../../styles/';

.component--note {
  position: absolute;
  height: calc(100% - 40px);
  width: 4px;
  bottom: 20px;
  background-color: #595959;
  // transition: all 0.2s ease-out;
  // padding-right: 20px;
  cursor: pointer;

  .component--note__base {
    position: absolute;
    bottom: 0;
    width: 4px;
    // border-radius: 50%;
    background-color: $color__white--tinted;
  }

  .component--note__intensity {
    position: absolute;
    width: 2px;
    height: 60px;
    left: 4px;
    bottom: 10px;
    background-color: $color__white--tinted;
  }

  .component--note__delay {
    position: absolute;
    width: 20px;
    height: 6px;
    right: 0px;
    bottom: 0px;
    background-color: rgba($color__red, 0.7);
    // opacity: 0.2;
  }

  &:hover {
    .component--note__base,
    .component--note__intensity {
      background-color: #ffcccc;
    }
    .component--note__intensity-control  {
      display: block;
    }
  }
}