@import '../../styles';

.page--dashboard {
  background-color: $color__gray--dark;
  width: 100vw;
  height: 100vh;
  position: relative;

  .page--dashboard__sim-launcher {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $color__white;
    padding: 8px;
  }
}